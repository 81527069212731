
<app-backheader></app-backheader>
<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="contianermobile">
            
            <div class="notificationwrap">
                <div class="row password" *ngFor="let tdata of notifications">
                    <div class="col-md-1  col-2">
                        <div class="text-success">
                            <!-- <img  src="https://acepunt.kushubmedia.com/uploads/passbook/{{tdata?.image}}" class="iconzo1"> -->
                            <!-- <img src="assets/images/ledgerIcon/cricket.png" class="iconzo1"> -->
                            <mat-icon *ngIf="tdata.status!='Approved'" class="iconzo">error</mat-icon>
                            <mat-icon *ngIf="tdata.status=='Approved'" class="iconzo1">check_circle</mat-icon>

                        </div>
                    </div>
                    <div class="col-md-11 col-10 setcolumn" (click)="openmodal(notificationdetaile ,tdata)">
                        <b class="p-0">
                            <span Class="settext">{{tdata?.name}}</span>
                            <span [ngClass]="tdata?.status == 'Approved' ? 'setlimit' : 'limitdanger'"
                                style="float:right;letter-spacing: 0px;font-weight: 400;font-size: 12px;">{{tdata?.amount}}</span></b>
                        <p class="setremark">
                            <span>{{tdata?.createdAt|date:'medium'}}
                                <!-- <span
                            style="float:right"
                            [ngClass]="tdata?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{tdata?.status}}</span> -->
                            </span><br>
                            <span>Remark : {{tdata?.remarks}}</span>
                        </p>
                    </div>
                    <!-- <div class="col-2 setcolumn">
                <img src="https://acepunt.kushubmedia.com/uploads/payments/paytm_upi.png" class="setback">
            </div> -->
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>


<!-- notification detaile -->

<ng-template #notificationdetaile>
    <div class="modal-header model_header bg-warning">
        <h4 class="modal-title">Notitfication Details</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide()" style="color:#fff; font-size: 18px;" >close</mat-icon>
        </a>
    </div>

    <div class="modal-body">
        <div>
            <h4 class="settitle">{{detaile?.message}}</h4>
        </div>
        <mat-list>
            <div>
                <h4 class="modal-head">Details</h4>
            </div>
            <div class="row">
                <div class="col-5">Coins</div>
                <div class="col-7">{{detaile?.amount}}</div>
            </div>
            <div class="row">
                <div class="col-5">Site Name</div>
                <div class="col-7">{{detaile?.sitename}}</div>
            </div>
            <div class="row">
                <div class="col-5">Site Url</div>
                <div class="col-7">{{detaile?.siteurl}}</div>
            </div>
            <div class="row">
                <div class="col-5">Remarks</div>
                <div class="col-7">{{detaile?.remarks}}</div>
            </div>
            <div class="row">
                <div class="col-5">Status</div>
                <div class="col-7">{{detaile?.status}}</div>
            </div>
        </mat-list>
    </div>
</ng-template>