import { Component, OnInit, ViewChild, ChangeDetectorRef,TemplateRef, Inject, OnDestroy, AfterContentChecked } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../services/users.service';
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit,OnDestroy {
  userDetails:any;
  ledgerList:any;
  days:number=1;
  modalRef:any;
  referLog:any;
  type_war:string='all';
  user: any;
    transactions: any;
    token: any;
    detaile: any;
    pasbooketails: any;
    logo: any;
    userdatak:any;
    reg_symbol: boolean = true;
    modaRef: BsModalRef;
    modalRefcancel: BsModalRef;
    cancelwithdrawForm: FormGroup;
    cancelmessage: any;
    hide: boolean = false;
   
    startDate: any;
    todayDate: any = new Date();
    endDate: any;
    isButtonDisabled: boolean =false;
    selectData: any;
    marketId: string;
    itemsPerPage: number = 20;
    currentPage: number = 1;
    total_items: number = 0;
    totalItems: number = 0;
    usrBet: any = [];
    statement_filter = 'All';


  constructor(private router: Router, private _location: Location,private usersService: UsersService,
    private fb: FormBuilder, private modalService: BsModalService, public datePipe: DatePipe,
    private socket: Socket,private toastr: ToastrService) {
      this.selectData = 0;
    this.todayDate = this.datePipe.transform(this.todayDate, "yyyy-MM-dd");
    const sevenDaysAgo: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    this.startDate = this.datePipe.transform(sevenDaysAgo, "yyyy-MM-dd");
    this.endDate = this.todayDate;
     }
  
 async  ngOnInit(){
    const data = await JSON.parse(sessionStorage.getItem('userDetails'));
    this.userdatak = await JSON.parse(sessionStorage.getItem('userDetails'));
    this.user = data.details;
    // console.log(this.user);
    this.createFrom();
    this.storeData();
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  somethingChanged1(event: any) {
    this.startDate = event.target.value;
  }
  somethingChanged2(event: any) {
    this.endDate = event.target.value;

  }

  getType(event: any) {
    this.selectData = event.target.value;
  }

 
  filterFun1(type, dataList: any) {

    if (type == "All") {
      return dataList;
    }
    else if (type == "game") {

      this.ledgerList = [];

      const arr = dataList.filter((res: any) => {

        if (res.subAction == 'AMOUNT_WON' || res.subAction == "AMOUNT_LOST") {

          return res;
        }
      });
      return arr;

    } else if (type == "deposit") {
      this.ledgerList = [];

      const arr = dataList.filter((res: any) => {

        if (res.subAction == 'BALANCE_WITHDRAWL' || res.subAction == "BALANCE_DEPOSIT") {

          return res;
        }
      });
      return arr;
    }


  }
  trasactions_games(type){
 this.type_war=type;

  }
  async storeData()
  {
    this.userDetails=await this.getDetials();
    this.getLedgerSoc();
  }

    getLedgerSoc()
    {

      const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        username: this.userDetails.details.username,
        action: {$in: ['BALANCE', 'AMOUNT', , 'COMMISION']},
        deleted: false,
        time: {
          $gte: this.startDate+"T00:00:00",
          $lte: this.endDate+"T23:59:00",
        },
      },
      sort: {time: -1},
    };
  
    this.socket.emit('get-logs', userdata);

    this.socket.on('get-logs-success', (function(data:any){
      if(data){ 
        this.toastr.success(data.message, 'Success!');
        this.ledgerList=data;
        console.log(this.ledgerList);
        this.removeListner();
      }
          
     }).bind(this));
    
  }

  

  removeListner()
  {
     this.socket.removeListener('get-logs-success');
  }

  refer_log(ledgerBook: TemplateRef<any>,x:any)
  {
    this.referLog=x;
    this.modalRef = this.modalService.show(
      ledgerBook,
      Object.assign({}, { class: 'bets-modal modal-lg' })
    );
  }

   filtt() {
    this.isButtonDisabled = true;
    setTimeout(()=>{
      this.isButtonDisabled = false;
    },5000)
    if (this.selectData == 1) {
      this.statement_filter = 'game';
      this.getLedgerSoc();
    } else if (this.selectData == 2) {
      this.statement_filter = 'deposit';
  this.getLedgerSoc();
    } else {
      this.statement_filter = 'All';
     this.getLedgerSoc();
    }

  }

    createFrom() {
      this.cancelwithdrawForm = this.fb.group({
        reason: ['', [Validators.required, Validators.minLength(12)]],
      })
    }
  
    openmodal(passbookdetaile: TemplateRef<any>, detailes) {
      this.hide=false;
      this.detaile = detailes;
      console.log(this.detaile);
      var data = { id: detailes._id ,   user_id: this.userdatak.details._id,}
     
        this.usersService.walletPost("gettransactionById", data).subscribe((response: any) => {
           console.log(response);
          this.pasbooketails = response.data;
        })
      
      this.modaRef = this.modalService.show(
        passbookdetaile,
        Object.assign({}, { class: 'passbookdetaile modal-lg' })
      );
    }
  
    cancelwithdraw() {
      if (this.cancelwithdrawForm.value.reason != '') {
        var data = {
          id: this.pasbooketails._id,
          user_id: this.userdatak.details._id,
          remarks: this.cancelwithdrawForm.value.reason,
        }
        console.log(data);
        this.usersService.walletPost("cancelWithdrawl" , data).subscribe((response: any) => {
          // console.log(response);
          this.cancelmessage = response;
          if (response) {
            // document.getElementById("openModalButton").click();
            this.toastr.success(response.message);
          }
        });
      }
  
    }
  
  
    cancel(success: TemplateRef<any>, detailes) {
      this.modalRefcancel = this.modalService.show(
        success,
        Object.assign({}, { class: 'success modal-lg' })
      );
    }
  
    backClicked() {
      // console.log('test')
      this._location.back();
     // this.router.navigate(['wallet']);
    }
  
    gonotification() {
      this.router.navigate(['wallet-notifications']);
    }

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
