<div class="loginPage" style="background-color: #2826269c"  >
    <div class="cancelicon" [routerLink]="['/dashboard']">
        <i class="material-icons">cancel</i>
    </div>
    <div class="login-main">
        
        <div class="login-inner">           
            <div *ngIf="logo!='clublucky7'" class="logo text-center">
                <img src="assets/logo/logo.svg"  alt="localost">
            </div>
            <div class="form">
                <form class="login-form"  [formGroup]= 'loginForm' (ngSubmit) ='onSignInClick()'>
                    <!-- <div class="login-title">LOGIN</div> -->
                    <div class="form-group" appearance="fill">
                    <label class="form-label">Username/Mobile No.</label>
                    <input type="text" class="form-control" placeholder="Enter Mobile number" formControlName='username'>
                    <mat-icon matSuffix>person_outline</mat-icon>
                    </div>
                
                    <div class="form-group" appearance="fill">
                        <label class="form-label">Password</label>
                        <input [type]="showPassword ? 'text' : 'password'"  placeholder="Enter Password"class="form-control" formControlName='password'>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="showPassword" (click)="showPassword = !showPassword">visibility</mat-icon>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="!showPassword" (click)="showPassword = !showPassword">visibility_off</mat-icon>
                    </div>

                    <!-- <mat-checkbox class="checkbox-div">Remember me!</mat-checkbox> -->
                    <div class="submit-btn">
                        <button mat-raised-button color="primary" [disabled]="loginButtonDisable">Login</button>
                    </div>
                    
                </form>
                
            </div>
        </div>
        
        <div *ngIf="this.logo!='paisaexch'" class="" style="text-decoration:underline;cursor:pointer;"><span style="padding-left:37px; " [routerLink]="['/login-otp']">Login With OTP</span><span *ngIf="regButon" class="" style="float:right;padding-right:37px;text-decoration:underline" [routerLink]="['/register/nocode']">Register</span></div>
        
    </div>
    <div style="display:table-row;vertical-align:bottom;" class="download-apk-btn text-center">
        <div class="row" style="margin: 5px 10px" *ngIf="logo=='clubosg' || logo=='clubprt'">
            <div class="col-12 text-enter support-icon">
                <a *ngIf="url_youtube" href="{{url_youtube}}" style="color: #ffc701">
                    <i class='fab fa-youtube fa-2x'></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  </a>
                  <a  *ngIf="url_insta" href="{{url_insta}}" style="color: #ffc701">
                    <i class='fab fa-instagram fa-2x'></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  </a>
                  <a  *ngIf="url_telegram" href="{{url_telegram}}" style="color: #ffc701">
                    <i class='fab fa-telegram fa-2x'></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  </a>
            </div>
          </div> 
        <!-- <button (click)="downloadApk();" mat-raised-button style="background-color:#fff;"><mat-icon class="android">android</mat-icon> Get ANDROID APP <mat-icon class="arrow">arrow_forward</mat-icon></button> -->
    </div>
    <!-- <div *ngIf="this.logo!='boomboombook'" class="text-center text-warning font-weight-bold mb-4" style="display:table-row;vertical-align:bottom;">Powered by OSG</div> -->
    <div *ngIf="this.logo!='boomboombook'" class="text-center mt-4" style="">Powered by acepunt.in</div>
</div>
