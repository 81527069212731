<header>
    <div class="header">
        <mat-toolbar class="mat-toolbar-row contianermobile">
            <div class="left-col">
                <a class="menubutton"  [routerLinkActive]="['active']"> 
                  <mat-icon (click)="backClicked()" class="back">arrow_back</mat-icon>
                </a> 
            </div>
            <div class="left-col">
                <span ><img src="assets/logo/logo_colored.svg" style="height: 20px " ></span>
            </div>
            <span class="div-spacer"></span>
            <div class="right-col">
                <ng-container *ngIf="loginButtnStatus">
                  <mat-icon class="refrsh" (click)="RefreshBal();">refresh</mat-icon>
                   <a mat-stroked-button class="balance" >
                        <!-- <mat-icon (click)="RefreshBal();">refresh</mat-icon> Ba: <br> -->
                        
                     <!-- <mat-icon>exposure</mat-icon> Ex :<br> -->
                    <div style="text-align: right; line-height: 12px;margin-top: 0px;margin-right: 8px; font-size: 10px;">
                      <text (click)="RefreshBal();">  {{walletBalance| number : '0.0-2'}} &nbsp;Bal</text><br> 
                        <text  [routerLink]="['/my-bet']"> {{exposure| number : '0.0-2'}} &nbsp;Exp</text>
                    </div> 
                  </a>
                   <button mat-stroked-button  class="buttonlogin" >
                    <!-- <mat-icon>login</mat-icon> -->
                 <a [routerLink]="['/wallet-deposit/deposit']">
                    <text class="loginbutt">Deposit</text>  
                 </a>
                 </button>
                </ng-container>
                <ng-container *ngIf="!loginButtnStatus">
                 
                 <button mat-stroked-button  class="buttonlogin" >
                    <!-- <mat-icon>login</mat-icon> -->
                 <a [routerLink]="['/login']">
                    <text class="loginbutt">Login</text>  
                 </a> <text class="loginbutt"> | </text> 
                 <a *ngIf="reg_symbol && this.logo!= 'paisaexch'" [routerLink]="['/register']" >
                    <text class="loginbutt">Signup</text>  
                 </a> 
                 </button>
                 <button mat-stroked-button  class="buttondemoo" >
                 <a *ngIf="reg_symbol && this.logo!= 'paisaexch'" >
                    <text class="demobutt">Demo</text>  
                 </a> 
                 </button>
                </ng-container>
            </div>
            
        </mat-toolbar>
        
    </div>
   
    
</header>

<mat-sidenav mode="side" #sidenavRight  position="start"  closed (click)="sidenavRight.toggle()" [routerLinkActive]="['active']" class="right-panel">
    <!-- <div class="sidebar-first-heading">
      
      <div class="close-btn" style="color:#fff"  closed (click)="sidenavRight.toggle()" [routerLinkActive]="['active']"> <mat-icon class="icosize">close</mat-icon></div>
      </div> -->
      <div class="left-col bgheader">
        <span (click)="inplay();"><img src="assets/logo/{{logo}}.png" [ngStyle]="{'max-width':getWidth()}" alt="{{logo}}"></span>
    </div>
    <div class="row user-profile align-items-center">
      <div class="col-xs-3">
        <i class="material-icons">person</i>
      </div>
      <div class="col-xs-3 username" *ngIf="userDetails?.details?.username">
        {{ userDetails?.details?.username }}
        <div class="last-login">
          <!-- <div>Last login</div>
                  <div>{{moment(userDetails?.details?.updatedAt).utcOffset("+05:30").format('lll')}}</div> -->
        </div>
      </div>
      <div class="col-xs-3 username" *ngIf="!userDetails?.details?.username">
        demouser
        <div class="last-login">
          <!-- <div>Last login</div>
                  <div>{{moment(userDetails?.details?.updatedAt).utcOffset("+05:30").format('lll')}}</div> -->
        </div>
      </div>
      <!-- <div class="sidebar-first-heading">
        <button *ngIf="tokenStatus" mat-stroked-button color="warn" (click)="logoutUser()">
          <mat-icon>logout</mat-icon>
        </button>
      </div> -->
      <!-- <div class="close-btn" style="color:#fff"  closed (click)="sidenavRight.toggle()" [routerLinkActive]="['active']"> <mat-icon class="icosize">close</mat-icon></div> -->
    </div>
    <!-- <div class="row" style="margin: 5px 10px">
      <div class="winning-card">
        <a style="color: #000">
          <img src="assets/icons/account_balance.png" />
          <div>Balance</div>
          <h5>
            <b>{{ walletBalance | number : "0.0-2" }}</b>
          </h5>
        </a>
      </div>
  
      <div class="winning-card" *ngIf="userDetails?.details?.manager!='OSG365'">
        <a style="color: #000">
          <i class="material-icons">casino</i>

          <div>Casino</div>
          <h5>
            <b>{{ casinoBal | number : "0.0-2" }}</b>
          </h5>
        </a>
      </div>
  
      <div class="exposure-card">
        <img src="assets/icons/exposure.png" />
        <div>Exposure</div>
        <h5>
          <b>{{ exposure | number : "0.0-2" }}</b>
        </h5>
      </div>
    </div> -->
  
  
  
    <div class="report-menu">
      <!-- <h4>Reports</h4> -->
      <ul class="menu-list">
        <li>
          <a class="dropdown-item" [routerLink]="['/profile']"><mat-icon class="material-icons iconsize" >person</mat-icon> Profile</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/wallet-withdraw/withdraw']"><mat-icon class="material-icons iconsize" >account_balance</mat-icon> Withdrawl details</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/ledger']"><mat-icon class="material-icons iconsize" >contacts</mat-icon>Account Statement</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/my-bet']"><mat-icon class="material-icons iconsize" >tour</mat-icon>Active Bets</a>
        </li>
        <li>
          <a class="dropdown-item" ><mat-icon class="material-icons iconsize" >rate_review</mat-icon>Edit Stacks</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/wallet-notifications']"><mat-icon class="material-icons iconsize" >notifications</mat-icon>Notifications</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/terms-condition']"><mat-icon class="material-icons iconsize" >admin_panel_settings</mat-icon>Rules</a>
        </li>
        <li>
          <a class="dropdown-item" ><mat-icon class="material-icons iconsize" >help</mat-icon>Help</a>
        </li>
        <!-- <li *ngIf="tokenStatus" (click)="logoutUser()">
          <a style="color: red !important"> <mat-icon>logout</mat-icon></a> LogOut
        </li> -->
        
        <div class="bottomside">
          <li *ngIf="loginButtnStatus" style="margin-bottom: 8px; line-height: 34px;">
            <a href="https://www.instagram.com/clubosg_official/?igsh=MXA0NDc5NjF3NzhtNg%3D%3D&utm_source=qr"><img src="assets/images/osgbook/icon_dark_instagram.svg"></a><a href="https://t.me/osgclub"> <img src="assets/images/osgbook/icon_dark_telegram.svg" ></a>
          </li>
          <li (click)="downloadApk()" class="donnlod">
          <a class="dropdown-item borderdownlo"   style="color:#1a3da6"><mat-icon class="material-icons iconsize"  style="color:#1a3da6">download</mat-icon>Download App</a>
          </li>
          <li  *ngIf="loginButtnStatus"  class= "logdhight" (click)="logoutUser()">
            <a  class="dropdown-item borderlogout" >Logout</a>
          </li>
          <li  *ngIf="!loginButtnStatus" class= "logdhight"  [routerLink]="['/login']">
            <a  class="dropdown-item borderlogout" >Login | SignUp</a>
          </li>
        </div>
      </ul>
    </div>
  </mat-sidenav>
  
 

