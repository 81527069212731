import { Component, OnInit, ViewChild,HostListener, ChangeDetectorRef,TemplateRef, Inject, OnDestroy, AfterContentChecked } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BetSlipBottomSheetComponent } from '../bet-slip-bottom-sheet/bet-slip-bottom-sheet.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'q';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Match } from '../model/match';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../services/users.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from '../services/login.service';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-ballbyball-detail',
  templateUrl: './ballbyball-detail.component.html',
  styleUrls: ['./ballbyball-detail.component.scss']
})
export class BallbyballDetailComponent implements OnInit {
 // @ViewChild(HeaderComponent, { static: true }) headerb: HeaderComponent;
  eventId: any;
  manualMatchStatus: boolean = true;
  userDetails: any;
  team1: string;
  team2: string;
  single_market: any = [];
  runners_odds: any;
  toss_runner: any;
  runners_book: any;
  match_odds: any = [];
  book_maker: any = [];
  fancy_market: any = [];
  bet1: any = false;
  bet2: any = false;
  eventName: any;
  eventType: any;
  matchDate: any;
  betloder: boolean = false;
  competitionNAme: any;
  liveUrl: SafeResourceUrl;
  graphicTvUrl: SafeResourceUrl;
  showScoreandTv: any = true;
  currentUrl: SafeResourceUrl;
  arrayObj: any = [];
  odds_bookmaker_db: any;
  session_db: any;
  netConnectService: Subscription;
  isConnected: any;
  internetConn: boolean = true;
  sessionrunnerProfit: any;
  userMatchStack: any = [{ label: "", price: "" }];
  match_odd_timer: any;
  fancy_timer: any;
  betPrice: any;
  betSize: any;
  disablePlaceBet: boolean = false;
  bannerImg: boolean;
  browser_details: any = { browserdetail: '', ipaddress: '' };
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  matchodds_max_limit_check: boolean;
  bookmaker_max_limit_check: boolean;
  fancy_max_limit_check: boolean;
  matchodds_min_limit: number;
  matchodds_max_limit: number;
  bookmaker_min_limit: number;
  bookmaker_max_limit: number;
  fancy_min_limit: number;
  fancy_max_limit: number;
  sessionMarketExposure: any;
  allBetData: any;
  config_max_odd_limit: any;
  matchoddMarket: any = [];
  toWinTossMarket: any = [];
  filterBetData: any = [];
  over_by_over_Market: any = [];
  ball_by_ballMarket: any = [];
  rule_type: string;
  all_market_min_max_limit: any;
  clsInterval: any;
  bet_filter_type: string = 'Matched';
  lodTimer: number = 0;
  resultA: any = [];
  mTimer: number = 0;
  matchDetail: any;
  modalscoreVideo: SafeResourceUrl;
  modalresult: string;
  headerb: any;
  betslipinfo: boolean;
  applyUserValidation: any;
  sportsSettingValues: any;
  betData: any;
  check_event_limit: any;
  fancyIdValidation: any;
  sessionSetting: any;
  ringOn: any;
  betSub:any;
  showbts: any;
  modalRef: BsModalRef;
  minMaxdata: any;
  max_limit: any;
  min_limit: any;
  rateLimit: any;
  @HostListener("document:visibilitychange", ["$event"])
  visibilitychange(event: any) {
    if (!document.hidden) {
      this.checkLoginAfterRun();
    }
  }
  subscription: Subscription;
  
  constructor(private _bottomSheet: MatBottomSheet,public router: Router, public route: ActivatedRoute, public socket: Socket,private modalService: BsModalService, public sanitizer: DomSanitizer, public toastr: ToastrService, public connectionService: ConnectionService, public httpClient: UsersService, public matchModel: Match, private cdr: ChangeDetectorRef) {
    this.route.paramMap.subscribe(param=>{
      this.eventId=param.get('eventId') // (+)Converts string 'id' to number
      });

      this.subscription =this.httpClient.returnUserBalance().subscribe
    (data => { //message contains the data sent from service
       this.getMyBets();
      
       
    });

    this.betSub =this.httpClient.returnBetStatus().subscribe
    (data => { //message contains the data sent from service
      this.lodTimer = this.all_market_min_max_limit/1000;
      this.setIntLod();
    });

    this.checkIntConn();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    if (JSON.parse(sessionStorage.getItem("address_info"))) {
      this.browser_details = JSON.parse(sessionStorage.getItem("address_info"));
    }
    this.eventId = '1122334455';
    // this.route.paramMap.subscribe((param) => {
    //   this.eventId = param.get("id"); // (+)Converts string 'id' to number
    // });
    this.sessionMarketExposure = {};
    this.checkIntConn();

    if (this.userDetails != null) {
      
      this.get_single_market();
      this.getminMaxLimit();
      this.socketDisconnect();
    }
   
  }

  ngOnInit(): void {
    // this.setupVisibilityChangeHandler();
    // this.resetInactivityTimer();

  }
 
  openbts(btopen: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(
      btopen,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }
  checkLoginAfterRun() {
    if (this.userDetails != null) {
      this.socketDisconnect(); 
    }
  }

  setIntLod() {
    this.clsInterval = setInterval(() => { this.decValue(); }, 1000);
  }

  decValue() {
    this.lodTimer--;
    if (this.lodTimer == 0) {
      clearInterval(this.clsInterval);
    }
  }

  // getSettings() {
  //   const payload = { siteurl: window.location.hostname };
  //   this.httpClient.rmTokenPost("getSetting", payload).subscribe((res: any) => {
  //     if (res.success) {
  //       this.all_market_min_max_limit = res.data;
  //     } else {
  //       this.toastr.error(res.message, "!Error");
  //     }
  //   });
  // }

  async getminMaxLimit() {
    const data = {
      eventTypeId:"b9",
      user_id: this.userDetails.details._id,
    };
    //console.log(data);
    this.httpClient.SocketPost("system-setting",data).subscribe((res:any)=>{
   //console.log(res);
    if (res.success===true) {
      this.minMaxdata = res?.response;
    if(this.minMaxdata){
      this.max_limit=this.minMaxdata?.data?.maxLimit;
      this.min_limit=this.minMaxdata?.data?.minLimit;
      this.all_market_min_max_limit=this.minMaxdata?.data?.betDelay;
      this.rateLimit=this.minMaxdata?.data?.rateLimit;
    }
    else{
      this.toastr.error("server error");
    }
    }
    else {
      this.toastr.error(res.message);
    }
    });

  }


  trackByFn(index: number, item: any): number {
    return item.id;
  }
  // checkInternetConnection
  checkIntConn() {
    this.netConnectService = this.connectionService
      .monitor()
      .subscribe((isConnected) => {
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.internetConn = true;
        } else {
          // bet_modal_close
          this.internetConn = false;
        }
      });
  }

  get_single_market() {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      eventId: this.eventId,
    };

    this.socket.emit("get-marketbyid", data);
    this.socket.on("get-marketbyid-success", function (data: any) {
      if (data[0]) {
        this.competitionNAme = data[0].competitionName
        this.eventName = data[0].eventName;
        let teams = this.eventName.split(' v ');
        this.team1 = teams[0]?.trim();
        this.team2 = teams[1]?.trim();
        this.eventType = data[0].eventTypeName;
        this.matchDate = data[0].openDate;

        // this.liveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        //   data[0].url
        // );
        // this.graphicTvUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        //   'https://s.sptpub.com/1394021f/tracker.html?eventId=' + this.eventId + '&sportId=2&lang=en&liveEvent=true&providers=Betradar'
        // );
        // this.graphicTvUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        //   'https://anim.365cric.com/#/score1/' + this.eventId
        // );
        // console.warn(this.graphicTvUrl);

        // if (data[0].score) {
        //   this.graphicTvUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        //     data[0].score
        //   );
        // }

        // if (data[0].url) {
        //   this.currentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        //     data[0].url);
        // }

        for (var i = 0; i < data.length; i++) {
          if (data[i].marketType === "MATCH_ODDS") {
            this.matchoddMarket.push(data[i]);
          }
        }

        // check STatus || min_max
        if (this.matchoddMarket.length > 0) {
          if (this.matchoddMarket[0].marketTypeStatus === 0) {
            this.manualMatchStatus = false;
          } else if (
            this.matchoddMarket[0].marketTypeStatus === "undefined"
          ) {
            this.manualMatchStatus = true;
          }

          if (this.matchoddMarket[0].matchodd_maxlimit > 0) {
            this.matchodds_max_limit_check = true;
            this.matchodds_min_limit =
              this.matchoddMarket[0].machodds_minlimit;
            this.matchodds_max_limit =
              this.matchoddMarket[0].matchodd_maxlimit;
          } else {
            this.matchodds_max_limit_check = false;
          }

          // bookmaker_max_min_check
          if (this.matchoddMarket[0].bookmaker_maxlimit > 0) {
            this.bookmaker_max_limit_check = true;
            this.bookmaker_min_limit =
              this.matchoddMarket[0].bookmaker_minlimit;
            this.bookmaker_max_limit =
              this.matchoddMarket[0].bookmaker_maxlimit;
          } else {
            this.bookmaker_max_limit_check = false;
          }
          if (this.matchoddMarket[0].session_maxlimit > 0) {
            this.fancy_max_limit_check = true;
            this.fancy_min_limit = this.matchoddMarket[0].session_minlimit;
            this.fancy_max_limit = this.matchoddMarket[0].session_maxlimit;
          } else {
            this.fancy_max_limit_check = false;
          }
        }
      }
      // else {
      //   this.toastr.error(data.message);
      // }
      this.socket.removeListener('get-marketbyid-success');
    }.bind(this)
    );

    this.event_pulse();
  }

  searchRunner(runners: any[], id: string): any {
    if (!runners) return null;
    for (var key in runners) {
      if (runners[key].selectionId == id) return runners[key].runnerName;
    }
  }

  event_pulse() {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      eventId: this.eventId,
    };
    this.socket.emit("add-to-room-ballbyball", data);
    this.socket.on("ballbyball-pulse-" + this.eventId, function (data: any) {
      if (
        data[0]?.length == 0
      ) {

      }
      else {
        this.match_odds = [];
        this.matchDetail = data;
        //console.log(this.matchDetail);
        this.match_odds.push(data);
        this.runners_odds = this.match_odds[0]?.marketBook?.runners;

        if (this.matchDetail.timers == 63) {
          this.getballbyballLastResult();
          this.getMyBets();
        //  this.refresh_bal();
        }
        if (this.matchDetail?.ballvideo) {

          if (this.matchDetail.timers <= 10) {
            this.bannerImg = true;
          }

          // new
          if (!this.liveUrl) {
            if(this.matchDetail.ballvideo){
              const url = this.prependURLIfMissing(this.matchDetail.ballvideo) + '?autoplay=1&mute=1';
              this.liveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
              //console.log(this.liveUrl);
              this.bannerImg = false;
            }
            

          }


        }
        else {
          // clearInterval(this.clsbetInterval);
          // clearTimeout(clearurltimout);
          this.liveUrl = '';
          this.bannerImg = true;
        }

        // timer
        if (this.matchDetail.timers <= 25) {
          this.mTimer = 0;
        } else {
          this.mTimer = this.matchDetail.timers - 25;
          // if (this.mTimer == 20) {
          //   clearInterval(this.clsbetInterval);
          // }
        }
      }

    }.bind(this))



    //  resultListner
    this.getballbyballLastResult();

    this.getMyBets();
   // this.getStakeButton();
  }

   prependURLIfMissing(inputString) {
    const urlPrefix = "https://vc9raw.sgp1.cdn.digitaloceanspaces.com/BallpBall/";
    
    // Check if the string already starts with the URL prefix
    if (!inputString.startsWith(urlPrefix)) {
      return urlPrefix + inputString;
    }
    
    // If the URL already exists, return the original string
    return inputString;
  }


  getballbyballLastResult() {
    //  resultListner
    const data1 = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      eventTypeId: "b9",
    };

    this.socket.emit("get-ballbyball-result", data1);
    this.socket.on(
      "get-ballbyball-result-success",
      function (data: any) {
        this.resultA = data;
        this.socket.removeAllListeners('get-ballbyball-result-success');
      }.bind(this)
    );
  }

  showTv(value: boolean) {

    this.showScoreandTv = value;
    if (this.showScoreandTv) {
      this.currentUrl = this.liveUrl;
    }
    else if (!this.showScoreandTv) {
      this.currentUrl = this.graphicTvUrl;
    }
  }

  eventSocketRemove() {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      eventId: this.eventId,
    };

    this.socket.emit("remove-from-room-ballbyball", data);
  }

  session_book(marketId: any) {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      marketId: marketId,
    };

    this.socket.emit("get-runner-profit", data);

    this.socket.on(
      "get-runner-profit-success",
      function (data: any) {
        if (data) {
          this.sessionrunnerProfit = "";
          this.sessionrunnerProfit = data.runnerProfit;
          this.socket.removeListener("get-runner-profit-success");
          //  session_book_modal
        }
      }.bind(this)
    );
  }

  checkMatchOddStatus(matchOddsData: any): boolean {
    if (matchOddsData.marketBook.status === "OPEN") {
      return false;
    } else {
      return true;
    }
  }

  checkWinTossStatus(matchOddsData: any): boolean {
    if (matchOddsData?.marketBook?.status === "OPEN") {
      return false;
    } else {
      return true;
    }
  }

  checkBookmakerStatus(matchOddsData: any, runner: any): boolean {
    if (
      matchOddsData.marketBook.status === "OPEN" &&
      runner.status === "ACTIVE"
    ) {
      return false;
    } else {
      return true;
    }
  }

    
  getOddsValue(price, selectionId, marketid, marketName, eventName, type, runnerName, market_type, amount) {
    clearTimeout(this.fancy_timer);
    clearTimeout(this.match_odd_timer);

    this.match_odd_timer = setTimeout(() => {
      //  bet_modal_close
      this.close_modal();
    }, 1500);

    this.betPrice = price;
    // 0 bet Matchodds/bookmaker
    if(this.rateLimit < this.betPrice){
      this.toastr.error("Bet not allowed graeter then" +" "+  this.rateLimit +" "+  "rate");
  return true;
    }
    if (market_type === "Special" && price <= 1) {
      return;
    } else {
      if (price == 0) {
        return;
      }
    }

    this.betslipinfo = true;
    this.betPrice = price;
  
      const betdataS = {
        user: this.userDetails,
        bet: {
          runnerId: selectionId,
          selectionName: runnerName,
          rate: price,
          stake: 0,
          marketId: marketid,
          marketName: marketName,
          marketType: market_type,
          eventId: this.eventId,
          eventName: eventName,
          type: type,
        },
      }
  
      this.arrayObj = {
        selection_id: selectionId,
        market_id: marketid,
        odds: price,
        stake: Math.round(amount),
        is_back: type,
        is_fancy: 0,
        MatchName: marketName,
        placeName: "",
        isManual: 0,
        is_session_fancy: "N",
      };

    // localStorage.setItem('type',type);
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    sessionStorage.setItem('betDataS', JSON.stringify(betdataS));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }
  getBetSlipDataForOdds() {

    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem('betList'));
    // this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    // if (this.IsToggle) {
    //   this.matchModel.tempArray[0].stake = this.oneClickSelectedBet;
    //   this.matchModel.tempArray[0].p_l = ((this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake) - this.matchModel.tempArray[0].stake);
    //   this.matchModel.loading1 = true;
    //   // this.Place_Order(this.matchModel.tempArray);
    // }
    this.openBetSlipBottomSheet(this.matchModel, this.arrayObj);
  }
  openBetSlipBottomSheet(data, arrayObj): void {
    const BetSlipbottomSheetRef = this._bottomSheet.open(BetSlipBottomSheetComponent, {
      data: { matchModel: this.matchModel, arrayObj: this.arrayObj, betPrice: this.betPrice, betData: this.betData, betSize: this.betSize,
        applyUserValidation:this.applyUserValidation, sportsSettingValues:this.sportsSettingValues,checkEventLimit : this.check_event_limit,fancyId : this.fancyIdValidation,
       session_setting : this.sessionSetting,ring_On:this.ringOn},
    });
    // BetSlipbottomSheetRef.afterDismissed().subscribe(() => {
    //   this.getFancyLiability()
    // });
  }

  close_modal() {
    const modelDiv = document.getElementById('betpopupmodal-modal');
    if (modelDiv != null) {
      modelDiv.className = 'modal fade hide';
      modelDiv.style.display = 'none';
    }
  }

  close_modal1() {
    const modelDiv = document.getElementById('ballbyballpopupmodal-modal');
    if (modelDiv != null) {
      modelDiv.className = 'modal fade hide';
      modelDiv.style.display = 'none';
    }
  }

  // fancy_bet_model
  setSessionValue(price, marketid, marketName, eventName, type, size, market_type) {

    // automatic_close_model_after_7sec
    clearTimeout(this.match_odd_timer);
    clearTimeout(this.fancy_timer);
    this.fancy_timer = setTimeout(() => {
      //  bet_modal_close
      this.close_modal();
    }, 15000);
    this.betPrice = price;
    this.betSize = size;
    // 0 fancy_bet_check
    if (price === 0) {
      return;
    }

    let newRate;
    if (size === 0) {
      newRate = 1;
    } else {
      newRate = size / 100;
    }

    const sessionBet = {
      user: this.userDetails,
      bet: {
        runnerId: 1,
        selectionName: price,
        rate: newRate,
        stake: 0,
        marketId: marketid,
        marketName: marketName,
        marketType: market_type,
        eventId: this.eventId,
        eventName: eventName,
        type: type,
      },
    };

    this.arrayObj = {
      fancy_id: "",
      market_id: marketid,
      odds: price,
      stake: 0,
      is_fancy: 1,
      is_back: type,
      MatchName: "",
      placeName: "",
      isManual: 0,
      size: size,
      is_session_fancy: "Y",
    };

    this.matchModel.isbetslipshow = true;
    this.session_db = sessionBet;
    // bet_modal_open
    const modelDiv = document.getElementById('betpopupmodal-modal');
    if (modelDiv != null) {
      modelDiv.className = 'modal fade show';
      modelDiv.style.display = 'block';
    }
  }


  

  // bet_place
  betSocket(data: any) {
    var code = true;
    setTimeout(() => {
      if (code) {
        this.toastr.error("bet rate changed!");
        this.betloder = false;
        this.socket.removeListener("place-bet-error");
        // bet_modal_close
        this.close_modal();
      }
    }, 15000);
    this.socket.emit("create-bet", data);
    this.socket.on(
      "place-bet-success",
      function (data: any) {
       // console.log(data);
        
        code = false;
        // debug
       // this.httpClient.refreshBet("listner");
        this.toastr.success(data.message);
        this.betloder = false;
        this.getMyBets();
        this.close_modal();
        //this.refresh_bal();
        this.socket.removeListener("place-bet-success");
      }.bind(this)
    );

    this.socket.on(
      "place-bet-error",
      function (data: any) {
        console.log(data);
        code = false;
        this.toastr.error(data.message);
        this.betloder = false;
        this.socket.removeListener("place-bet-error");
        this.socket.removeListener("place-bet-success");
        this.close_modal();
        
      }.bind(this)
    );
  }

  // get_user_bal
  

  calculateP_lOnStack(stake, stkbtn, isback, back) {
    this.stakeIds = this.arrayObj.stakeIds;
    this.stackval = back.stake == "" || back.stake == null ? 0 : back.stake;
    // back.stake = parseFloat(stkbtn);
    back.stake = parseFloat(stkbtn) + parseFloat(this.stackval);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = back.odds * back.stake - back.stake;
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (
        back.odds <= this.config_max_odd_limit ||
        back.is_session_fancy == "Y" ||
        true
      ) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = pval * back.stake - back.stake;
          } else {
            back.p_l = back.odds * back.stake - back.stake;
          }
          this.matchModel.ProfitLoss = back.p_l;
        }
      }
    } else {
      let msg = "";
      if (back.is_session_fancy == "Y") {
        msg =
          "Max session bet liability is " + parseFloat(back.max_bet_liability);
      } else {
        msg =
          "Max market bet liability is " + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  filterBets() {
    if (this.bet_filter_type === 'Fancy') {
      this.filterBetData = this.allBetData.filter((item) => {
        return item.marketType === "SESSION";
      });
    
    }
    else {
      this.filterBetData = this.allBetData;
      
    }

  }

  getMyBets() {
    this.allBetData = [];
    this.filterBetData = [];
    const getBet = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      filter: {
        eventId: this.eventId,
        username: this.userDetails.details.username,
        deleted: false,
        result: "ACTIVE",
      },
      sort: { placedTime: -1 },
    };

    this.socket.emit("get-bets", getBet);

    this.socket.on(
      "get-bets-success",
      function (data: any) {
        if (data.length > 0) {
          if (data[0].eventId === this.eventId) {
            this.allBetDataLength = 0;
            this.filterBetData = data;
            
            this.allBetData = data;
            for (var i = 0; i < this.allBetData.length; i++) {
              if (this.allBetData[i].marketType == "SESSION") {
                var filterData = this.allBetData.filter((val) => {
                  
                  return this.allBetData[i].marketId == val.marketId;
                });

                this.getbetSessionalue(filterData, this.allBetData[i].marketId);
                //this.sessionMarketExposure[this.allBetData[i].marketId]=filterData.map(item=>item.ratestake).reduce( (a,b)=> a+b);
              }
            }
            this.allBetDataLength = this.allBetData.length;
          }

          this.socket.removeAllListeners('get-bets-success');
        }
      }.bind(this)
    );
  }

  getbetSessionalue(bets: any, marketId: any) {
    var runnerProfit = {};
    var total = 0;
    var totalArr = [];
    var min = 0,
      max = 0,
      bc = 0;
    for (var j = 0; j < bets.length; j++) {
      if (j == 0) {
        min = parseInt(bets[j].selectionName);
        max = parseInt(bets[j].selectionName);
      } else {
        if (parseInt(bets[j].selectionName) > max)
          max = parseInt(bets[j].selectionName);
        if (parseInt(bets[j].selectionName) < min)
          min = parseInt(bets[j].selectionName);
      }
    }

    for (var i = min - 1; i < max + 1; i++) {
      var result = i;
      var c2 = 0,
        maxLoss = 0;
      for (var bi1 = 0; bi1 < bets.length; bi1++) {
        c2++;
        var b1 = bets[bi1];
        if (b1.type == "Back") {
          if (result >= parseInt(bets[bi1].selectionName)) {
            maxLoss += Math.round(bets[bi1].rate * bets[bi1].stake);
          } else {
            maxLoss -= bets[bi1].stake;
          }
        } else {
          if (result < parseInt(bets[bi1].selectionName)) {
            maxLoss += bets[bi1].stake;
          } else {
            maxLoss -= Math.round(bets[bi1].rate * bets[bi1].stake);
          }
        }

      }
      runnerProfit[i] = maxLoss;
    }

    var w = null;
    if (w != null) {
      if (runnerProfit[w] == null) {
        runnerProfit[w] = 0;
      }
    }
    for (const t in runnerProfit) {
      totalArr.push(runnerProfit[t]);
    }
    this.sessionMarketExposure[marketId] = Math.min.apply(Math, totalArr); // 1;

  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {
    if (stake <= 0) {
      back.stake = 0;
    } else {
      this.stakeIds = this.matchModel.stakeIds;
      this.stackval = back.stake == "" || back.stake == null ? 0 : back.stake;
      back.stake = parseFloat(stkbtn);

      if (
        parseFloat(back.stake) <= parseFloat(back.max_bet_liability) ||
        true
      ) {
        this.isActive = true;
        back.p_l = back.odds * back.stake - back.stake;
        if (isback == 0) {
          this.total_liability = back.p_l;
        } else {
          this.total_liability = back.stake;
        }
        this.matchModel.calculateProfitLoss(back);
        if (
          back.priceVal <= this.config_max_odd_limit ||
          back.is_session_fancy == "Y" ||
          true
        ) {
          if (back.priceVal > 0) {
            const tempback = back;
            this.isActive = true;
            this.total_liability = 0;
            if (back.isManual) {
              const pval = back.pricefinal + 1;
              back.p_l = pval * back.stake - back.stake;
            } else {
              back.p_l = back.priceVal * back.stake - back.stake;
            }
            this.matchModel.ProfitLoss = back.p_l;
          }
        }
      } else {
        let msg = "";
        if (back.is_session_fancy == "Y") {
          msg =
            "Max session bet liability is " +
            parseFloat(back.max_bet_liability);
        } else {
          msg =
            "Max market bet liability is " + parseFloat(back.max_bet_liability);
        }
        back.stake = parseFloat(back.max_bet_liability);
        this.isActive = false;
      }
    }
  }

  calProLoss(a: any, data: any, index: number, matchOddsData: any) {
    if (a && this.allBetData) {
      let test = this.allBetData.filter((item) => {
        return item.marketName == matchOddsData.marketName;
      });

      let betsValue = test.filter((item) => {
        if (a[index].runnerName != undefined) {
          return item.selectionName == a[index].runnerName;
        } else {
          return item.selectionName == data.runners[index].runnerName;
        }
      });

      let laystaketotal = test.filter((item) => {
        if (a[index].runnerName != undefined) {
          return item.selectionName != a[index].runnerName;
        } else {
          return item.selectionName != data.runners[index].runnerName;
        }
      });

      let backData = betsValue.filter((item) => {
        return item.type == "Back";
      });

      let layData = betsValue.filter((item) => {
        return item.type == "Lay";
      });

      let oppBack = laystaketotal.filter((item) => {
        return item.type == "Back";
      });

      let totalOppBack = 0;
      oppBack.map((b) => {
        totalOppBack = totalOppBack + b.stake;
      });

      let oppLay = laystaketotal.filter((item) => {
        return item.type == "Lay";
      });

      let totalOppLay = 0;
      oppLay.map((b) => {
        totalOppLay = totalOppLay + b.stake;
      });

      let backvalue = 0;
      backData.map((b) => {
        let back = b.stake * (b.rate - 1);
        backvalue = backvalue + back;
      });

      let layvalue = 0;
      layData.map((b) => {
        let lay = b.stake * (b.rate - 1);
        layvalue = layvalue + lay;
      });

      let backtotal = backvalue - totalOppBack;
      let laytotal = totalOppLay - layvalue;

      let markettotal;

      markettotal = backtotal + laytotal;

      return markettotal;
    }
  }

  openModalCondition(value: string) {
    this.rule_type = value;
    //  rule_modal
  }

  isNotANumber(value: any): boolean {
    return isNaN(value);
  }

  socketDisconnect() {
    this.socket.on(
      "disconnect",
      function (data: any) {
        this.socketConnect();
      }.bind(this)
    );
  }

  socketConnect() {
    
    this.socket.emit("connected");
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      eventId: this.eventId,
    };
    this.socket.emit("add-to-room-ballbyball", data);
  }

  cashOut(market: any) {
    let greenAmount = 0;
    let redAmount = 0;
    let currentbackredodds = 0;
    let currentlaygrrenodds = 0;
    let runners;
    let runners_point = [];
    let runners_v = {};
    for (var i = 0; i < market.marketBook.runners.length; i++) {

      if (this.calProLoss(market?.runners, market?.marketBook, i, market) > 0) {
        greenAmount = this.calProLoss(market?.runners, market?.marketBook, i, market);
        currentlaygrrenodds = market.marketBook.runners[i]?.availableToLay?.price;
        runners = market.marketBook.runners[i];
        runners_point.push(greenAmount)
      }
      else {
        redAmount = this.calProLoss(market?.runners, market?.marketBook, i, market);
        runners_point.push(redAmount);
        runners_v[i] = market.marketBook.runners[i];
        currentbackredodds = market.marketBook.runners[i]?.availableToBack?.price;

      }

    }

    let backStake = (greenAmount - redAmount) / currentbackredodds;
    let layStake = (greenAmount - redAmount) / currentlaygrrenodds;
    if (!runners) {
      if (Math.trunc(runners_point[0]) == Math.trunc(runners_point[1])) {

        return;
      }

      if (runners_point[0] > runners_point[1]) {
        runners = market.marketBook.runners[0];
        greenAmount = this.calProLoss(market?.runners, market?.marketBook, 0, market);
        currentlaygrrenodds = market.marketBook.runners[0]?.availableToLay?.price;
        redAmount = this.calProLoss(market?.runners, market?.marketBook, 1, market);
      }
      else {
        runners = market.marketBook.runners[1];
        greenAmount = this.calProLoss(market?.runners, market?.marketBook, 1, market);
        currentlaygrrenodds = market.marketBook.runners[1]?.availableToLay?.price;
        redAmount = this.calProLoss(market?.runners, market?.marketBook, 0, market);
      }

    }


    if (layStake === Infinity) {
      layStake = (greenAmount - redAmount) / currentlaygrrenodds;
    }


    this.getOddsValue(runners?.availableToLay?.price, runners?.selectionId, market?.marketId, market?.marketName, market?.eventName, 0, this.searchRunner(market?.runners, runners?.selectionId), market?.marketType, layStake);

  }
  
  


  cashOut_value(market: any) {
    let runners_point = [];
    for (var i = 0; i < market?.marketBook?.runners?.length; i++) {

      runners_point.push(this.calProLoss(market?.runners, market?.marketBook, i, market))
    }
    if (runners_point[0] === 0) { return 0 };
    if (Math.trunc(runners_point[0]) == Math.trunc(runners_point[1])) {
      return 0;
    }
    else {
      return 1;
    }

  }

  scoreVideoModal(result: any) {
    const url = 'https://vc9raw.sgp1.cdn.digitaloceanspaces.com/BallpBall/' + result.ballvideo + '?autoplay=1&mute=1';
    this.modalscoreVideo = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.modalresult = result.ballrun;

    const modelDiv = document.getElementById('ballbyballpopupmodal-modal');
    if (modelDiv != null) {
      modelDiv.className = 'modal fade show';
      modelDiv.style.display = 'block';
    }
    // console.warn(this.resultA[index]);
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.userDetails != null) {
      this.netConnectService.unsubscribe();
      this.eventSocketRemove();
      this.socket.removeAllListeners('disconnect');
      this.socket.removeAllListeners('get-marketbyid-success');
      this.socket.removeAllListeners("");
      sessionStorage.removeItem('eventId');
    }
  }

}
