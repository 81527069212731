<mat-sidenav-container fullscreen class="pageledger">
    <mat-sidenav-content>
        <app-backheader></app-backheader>
        <div class=" row commenfilter">
            <div class="col-4">
              <label class="filterlabel">From Date:</label>
              <input name="startDate" type="date" value="{{startDate}}" (change)="somethingChanged1($event)">
            </div>
            <div class="col-4">
              <label class="filterlabel">To Date:</label>
              <input name="endDate" type="date" value="{{endDate}}" (change)="somethingChanged2($event)">
            </div>
            <div class="col-4">
              <button [disabled]="isButtonDisabled" class="btnfilter" (click)="filtt()">SUBMIT</button>
            </div>
          </div>
      
        <div class="bottom-menu">
          <mat-list> 
        <mat-list-item (click)="trasactions_games('all')" [ngClass]="{'active': type_war === 'all'}">  <span>Transactions</span></mat-list-item>
        <mat-list-item (click)="trasactions_games('4');" [ngClass]="{'active': type_war === '4'}">  <span>Cricket</span></mat-list-item>
        <mat-list-item (click)="trasactions_games('2');" [ngClass]="{'active': type_war === '2'}"> <span>Tennis</span></mat-list-item> 
        <mat-list-item (click)="trasactions_games('1');" [ngClass]="{'active': type_war === '1'}">  <span>Soccer</span></mat-list-item>
        <mat-list-item (click)="trasactions_games('b9');" [ngClass]="{'active': type_war === 'b9'}">  <span>Ball By Ball</span></mat-list-item>
        <mat-list-item (click)="trasactions_games('aviator');" [ngClass]="{'active': type_war === 'aviator'}">  <span>Aviator</span></mat-list-item>
        <mat-list-item (click)="trasactions_games('v9');" [ngClass]="{'active': type_war === 'v9'}">  <span>Virtual Cricket</span></mat-list-item>
        <mat-list-item (click)="trasactions_games('c9');" [ngClass]="{'active': type_war === 'c9'}"> <span>Casino </span> </mat-list-item>
       
        <mat-list-item (click)="trasactions_games('gocasino');" [ngClass]="{'active': type_war === 'gocasino'}"> <span>Go Casino </span> </mat-list-item>
        
        <mat-list-item (click)="trasactions_games('gocrash');" [ngClass]="{'active': type_war === 'gocrash'}"> <span>Go Crash </span> </mat-list-item>
          </mat-list>
      </div>

      	<div class=" mt-3">
            
           <!-- accordion -->
           <ng-container *ngFor="let x of ledgerList"> 
              <div *ngIf="x?.eventTypeId==this.type_war " [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'accGrnColor' : 'accRedColor'" class="mt-2 mx-2">
                
              <div class="card" style="background-color:#fff;">
                <div class="container-fluild"> 
                <div class="row">
                  <div class="col-2 p-0">
                    <div class="card-body text-success">
                      <img *ngIf="x.eventTypeId==4" src="assets/images/ledgerIcon/cricket.png" class="iconzo1">
                      <img *ngIf="x.eventTypeId==1" src="assets/images/ledgerIcon/soccer-player.png" class="iconzo1">
                      <img *ngIf="x.eventTypeId==2" src="assets/images/ledgerIcon/tennis.png" class="iconzo1">
                      <img *ngIf="x.eventTypeId=='c9'" src="assets/images/ledgerIcon/casino.png" class="iconzo1">
                      <img *ngIf="x.eventTypeId=='aviator'" src="assets/images/ledgerIcon/rocket.png" class="iconzo1">
                      <img *ngIf="x.eventTypeId=='v9'" src="assets/images/ledgerIcon/virtual.png" class="iconzo2">
                      <img *ngIf="x.eventTypeId=='b9'" src="assets/images/ledgerIcon/ball.png" class="iconzo1">
                      <img *ngIf="x.subAction=='BALANCE_DEPOSIT'" src="assets/images/ledgerIcon/deposit.png" class="iconzo1">
                      <img *ngIf="x.subAction=='BALANCE_WITHDRAWL'" src="assets/images/ledgerIcon/withdrawal.png"class="iconzo1">
                    </div>
                  </div>
                  <div class="col-10 p-0">
                   <b class="p-0"><span [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'text-success' : 'text-danger'"><span *ngIf="x?.subAction==='AMOUNT_WON'&& x?.action==='COMMISION' else ref">{{x.totalamount|number : '1.0-2'}} </span><ng-template #ref>{{x?.amount|number : '1.0-2'}}</ng-template></span><span style="float:right;">{{x.newLimit|number : '1.0-2'}}</span></b>
                   <!-- Depo/Withd... -->
                   <ng-container *ngIf="!x?.eventName">
                    <p class="m-0"><span *ngIf="x.subAction=='BALANCE_DEPOSIT'">Deposite</span><span *ngIf="x.subAction=='BALANCE_WITHDRAWL'">Withdrawl</span></p>
                    <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
                   <p class="addnew m-0">{{x?.remark}}<mat-icon (click)="refer_log(ledgerBook,x)"> add </mat-icon></p>
                   
                   </ng-container>
  
                     <!-- Cricket/Soccer... -->
                     <ng-container *ngIf="x?.eventTypeId !='v9'">
                      <p class="m-0">{{x?.eventName}}</p>
                      <p class="m-0">{{x?.marketName}}</p>
                      <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
                     </ng-container>
                     <ng-container *ngIf="x?.eventTypeId =='v9'">
                      <p class="m-0">{{x?.eventTypeName}}</p>
                      <p class="m-0">{{x?.eventName}}</p>
                      <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
                     </ng-container>
                   <p class="m-0">{{x.time|date:'medium'}}</p>
                  </div>
                </div>
              </div>
               
              </div>
                  <mat-accordion *ngIf="false" class="example-headers-align" multi>
                      <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header class="right-aligned-header">
                          <mat-panel-title >
                            <p>{{x?.eventTypeName}} </p>&nbsp; 
                            
                          </mat-panel-title>
                          <mat-panel-description>
                              <div class="font-weight-bold"><span [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'text-success' : 'text-danger'">
                                  {{x.amount|number : '1.0-2'}}&nbsp;&#8377;</span>&nbsp;&nbsp;&nbsp;{{x.newLimit|number : '1.0-2'}}
                                  
  
                              </div>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <!-- <hr style="margin:0px;" [ngStyle]="x.subAction=='BALANCE_DEPOSIT' ?{'background': 'green'} : {'background': 'red'}"> -->
                        <!-- Depo/Withd... -->
                        <div *ngIf="!x?.eventName" class="row mt-2">
                          <div class="col-2 font-weight-bold">
                              {{x.newLimit|number : '1.0-2'}}
                              <p style="margin-bottom:0px;">{{x.amount|number : '1.0-2'}}</p>
                             
                                <!-- <span [ngStyle]="x.subAction=='BALANCE_DEPOSIT' ?{'c{{x.description}}olor': 'green'} : {'color': 'red'}"><span *ngIf="x.subAction=='BALANCE_DEPOSIT'">Deposite</span><span *ngIf="x.subAction=='BALANCE_WITHDRAWL'">Withdrawl</span></span> -->
                          </div>
                          <div class="col-10">
                             <span class="font-weight-bold">Description:&nbsp;</span>
                             {{x.time|date:'medium'}}
                          </div>
                        </div>
  
                        <!-- Cricket/Soccer... -->
  
                        <div *ngIf="x?.eventName" class="row mt-2">
                          <div class="col-4 font-weight-bold text-success">
                              {{x?.marketId}}<p>{{x?.eventId}}</p>
                          </div>
                          <div class="col-4 text-center font-weight-bold">
                            {{x?.eventName}}
                          </div>
  
                          <div class="col-4 font-weight-bold">
                            {{x.newLimit}}
                            <p style="margin-bottom:0px;">{{x.amount|number : '1.0-2'}}</p>
                              <span>{{x.marketName}}</span>
                          </div>
  
                          <div class="col-8">
                            <span class="font-weight-bold">Description:&nbsp;</span>{{x.description}}
                         </div>
  
                        </div>
                      
                      </mat-expansion-panel>
               </mat-accordion>
              </div>
              
              <div *ngIf="this.type_war =='all'" >
                <div  *ngIf="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='BALANCE_WITHDRAWL'" [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'accGrnColor' : 'accRedColor'" class="mt-2 mx-2">

                
                <div class="card" style="background-color:#fff;">
                  <div class="container-fluild"> 
                  <div class="row">
                    <div class="col-2 p-0">
                      <div class="card-body text-success">
                        <img *ngIf="x.eventTypeId==4" src="assets/images/ledgerIcon/cricket.png" class="iconzo1">
                        <img *ngIf="x.eventTypeId==1" src="assets/images/ledgerIcon/soccer-player.png" class="iconzo1">
                        <img *ngIf="x.eventTypeId==2" src="assets/images/ledgerIcon/tennis.png" class="iconzo1">
                        <img *ngIf="x.eventTypeId=='c9'" src="assets/images/ledgerIcon/casino.png" class="iconzo1">
                        <img *ngIf="x.eventTypeId=='aviator'" src="assets/images/ledgerIcon/rocket.png" class="iconzo1">
                        <img *ngIf="x.eventTypeId=='v9'" src="assets/images/ledgerIcon/virtual.png" class="iconzo2">
                        <img *ngIf="x.eventTypeId=='b9'" src="assets/images/ledgerIcon/ball.png" class="iconzo1">
                        <img *ngIf="x.subAction=='BALANCE_DEPOSIT'" src="assets/images/ledgerIcon/deposit.png" class="iconzo1">
                        <img *ngIf="x.subAction=='BALANCE_WITHDRAWL'" src="assets/images/ledgerIcon/withdrawal.png"class="iconzo1">
                      </div>
                    </div>
                    <div class="col-10 p-0">
                     <b class="p-0"><span [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'text-success' : 'text-danger'"><span *ngIf="x?.subAction==='AMOUNT_WON'&& x?.action==='COMMISION' else ref">{{x.totalamount|number : '1.0-2'}} </span><ng-template #ref>{{x?.amount|number : '1.0-2'}}</ng-template></span><span style="float:right;">{{x.newLimit|number : '1.0-2'}}</span></b>
                     <!-- Depo/Withd... -->
                     <ng-container *ngIf="!x?.eventName">
                      <p class="m-0"><span *ngIf="x.subAction=='BALANCE_DEPOSIT'">Deposite</span><span *ngIf="x.subAction=='BALANCE_WITHDRAWL'">Withdrawl</span></p>
                      <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
                     <p class="addnew m-0">{{x?.remark}}<mat-icon (click)="refer_log(ledgerBook,x)"> add </mat-icon></p>
                     
                     </ng-container>
    
                       <!-- Cricket/Soccer... -->
                       <ng-container *ngIf="x?.eventTypeId !='v9'">
                        <p class="m-0">{{x?.eventName}}</p>
                        <p class="m-0">{{x?.marketName}}</p>
                        <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
                       </ng-container>
                       <ng-container *ngIf="x?.eventTypeId =='v9'">
                        <p class="m-0">{{x?.eventTypeName}}</p>
                        <p class="m-0">{{x?.eventName}}</p>
                        <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
                       </ng-container>
                     <p class="m-0">{{x.time|date:'medium'}}</p>
                    </div>
                  </div>
                </div>
                 
                </div>
                    <mat-accordion *ngIf="false" class="example-headers-align" multi>
                        <mat-expansion-panel [expanded]="true">
                          <mat-expansion-panel-header class="right-aligned-header">
                            <mat-panel-title >
                              <p>{{x?.eventTypeName}} </p>&nbsp; 
                              
                            </mat-panel-title>
                            <mat-panel-description>
                                <div class="font-weight-bold"><span [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'text-success' : 'text-danger'">
                                    {{x.amount|number : '1.0-2'}}&nbsp;&#8377;</span>&nbsp;&nbsp;&nbsp;{{x.newLimit|number : '1.0-2'}}
                                    
    
                                </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <!-- <hr style="margin:0px;" [ngStyle]="x.subAction=='BALANCE_DEPOSIT' ?{'background': 'green'} : {'background': 'red'}"> -->
                          <!-- Depo/Withd... -->
                          <div *ngIf="!x?.eventName" class="row mt-2">
                            <div class="col-2 font-weight-bold">
                                {{x.newLimit|number : '1.0-2'}}
                                <p style="margin-bottom:0px;">{{x.amount|number : '1.0-2'}}</p>
                               
                                  <!-- <span [ngStyle]="x.subAction=='BALANCE_DEPOSIT' ?{'c{{x.description}}olor': 'green'} : {'color': 'red'}"><span *ngIf="x.subAction=='BALANCE_DEPOSIT'">Deposite</span><span *ngIf="x.subAction=='BALANCE_WITHDRAWL'">Withdrawl</span></span> -->
                            </div>
                            <div class="col-10">
                               <span class="font-weight-bold">Description:&nbsp;</span>
                               {{x.time|date:'medium'}}
                            </div>
                          </div>
    
                          <!-- Cricket/Soccer... -->
    
                          <div *ngIf="x?.eventName" class="row mt-2">
                            <div class="col-4 font-weight-bold text-success">
                                {{x?.marketId}}<p>{{x?.eventId}}</p>
                            </div>
                            <div class="col-4 text-center font-weight-bold">
                              {{x?.eventName}}
                            </div>
    
                            <div class="col-4 font-weight-bold">
                              {{x.newLimit}}
                              <p style="margin-bottom:0px;">{{x.amount|number : '1.0-2'}}</p>
                                <span>{{x.marketName}}</span>
                            </div>
    
                            <div class="col-8">
                              <span class="font-weight-bold">Description:&nbsp;</span>{{x.description}}
                           </div>
    
                          </div>
                        
                        </mat-expansion-panel>
                 </mat-accordion>
                </div>
                </div>       
           </ng-container>
           <div style="margin-top:15rem;font-weight:500;" class="text-center" *ngIf="ledgerList?.length==0">There is no data to display</div>
        </div>

        <ng-template #ledgerBook>
          <div class="modal-header model_header bg-warning">
              <h4 class="modal-title">Refer Event </h4>
              <a type="button" class="close" data-dismiss="modal">
                  <mat-icon (click)="modalRef.hide()">close</mat-icon>
              </a>
          </div>
      
          <div class="modal-body" style="height:230px">
              <div class="table-div modeltable">
                  <table class="table table-bordered">
                      <tr>
                        <th class="text-left" align="left">DateTime</th>
                          <th class="text-left" align="left">Event</th>
                          <th class="text-left" align="left">Market</th>
                          <th class="text-left" align="left">Amount</th>
                      </tr>
                      <tr style="color:'white'" *ngFor="let m of referLog?.ref_log;">
                         <td>
                              {{m.time|date:"medium"}}
                         </td>
                         <td>
                          {{m.eventName}}
                     </td>
                     <td>
                      {{m.marketName}}
                 </td>
                 <td>
                  {{-1*m.settle_amount}}
             </td>
                      </tr>
                  </table>
                  <button style="float:right" class="modalbtn text-white" (click)="modalRef.hide()">Close</button>
              </div>
          </div>
      </ng-template>

        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- Passbook detaile -->

