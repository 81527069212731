<app-backheader></app-backheader>
<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <div class="contianermobile">
            <!-- <div class="header">
                <mat-toolbar class="mat-toolbar-row">
                    <div class="left-col">
                        <mat-icon (click)="backClicked()">keyboard_arrow_left</mat-icon>
                    </div>
                    <div>
                        <span><img src="../../assets/logo/logo_colored.svg" alt="" style="height: 20px;"></span>
                        <span class="sethead">Withdraw Details</span>
                    </div>
                    <span class="example-spacer"></span>
                </mat-toolbar>
            </div> -->
            <div class="pagewid-detai-wrap">
                <div class="withdrawddd">
                    <span>Withdraw Details</span>
                </div>
                <mat-accordion class="example-headers-align">
                    <mat-expansion-panel class="panel" *ngFor="let detaile of withdrawmethod; first as isFirst"
                        [expanded]="isFirst">
                        <mat-expansion-panel-header class="panelheader">
                            <mat-panel-title class="settitle"> {{detaile?.type}} </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="content-sectionde">
                            <div class="text-center">
                                <button class="btn btn-block btn-success" (click)="addbank(bankadd ,detaile)">
                                    Add New {{detaile?.type}}
                                </button>
                            </div>
                            <div class="row setpanel" *ngFor="let bank of detaile.withdrawns">
                                <div class="col-6 setname">
                                    Name
                                </div>
                                <div class="col-6 setdetaile">
                                    {{bank?.name}}
                                </div>
                                <div class="col-6 setname" *ngIf="bank.type =='Bank'">
                                    Account No.
                                </div>
                                <div class="col-6 setdetaile" *ngIf="bank.type =='Bank'">
                                    {{bank?.accnumber}}
                                </div>

                                <div class="col-6 setname" *ngIf="bank.type =='Google Pay'|| bank.type =='Phone Pay'|| bank.type =='Paytm'|| bank.type =='Pay Zapp'">
                                    UPI Number
                                </div>

                                <div class="col-6 setdetaile"  *ngIf="bank.type =='Google Pay'|| bank.type =='Phone Pay'|| bank.type =='Paytm'|| bank.type=='Pay Zapp'">
                                    {{bank?.upi}}
                                </div>
                                <div class="col-6 setname" *ngIf="bank.type =='UPI'">
                                    UPI Id
                                </div>

                                <div class="col-6 setdetaile"  *ngIf="bank.type =='UPI'">
                                    {{bank?.upi}}
                                </div>

                                <div class="col-6 setname" *ngIf="bank.type =='Bank'">
                                    IFSC
                                </div>
                                <div class="col-6 setdetaile" *ngIf="bank.type =='Bank'">
                                    {{bank?.ifsc}}
                                </div>
                                <div class="col-6 setname" *ngIf="bank.type =='Bank'">
                                    Bank Name
                                </div>
                                <div class="col-6 setdetaile" *ngIf="bank.type =='Bank'">
                                    {{bank?.bankName}}
                                </div>
                                <div class="col-12 setbtndiv">
                                    <!-- <button class="btn btn-block btn-danger" (click)="deletebank(bank._id)"> -->
                                    <button class="btn btn-block btn-danger" (click)="openmodel(success ,bank._id)">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <div *ngIf="detaile==0" class="col-12 text-center setdetaile">
                                <span>No Data Found?</span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #bankadd>

    <div class="modal-header model_header bg-warning">
        <h4 class="modal-title">Please Enter Bank Details</h4>
        <a type="button" class="close" data-dismiss="modal">
            <mat-icon (click)="modalRef.hide() ;formdetaile()">close</mat-icon>
        </a>
    </div>

    <div class="row setform">

        <div class="col-12" *ngIf="banktype == 'Bank'">
            <form class="example-form" [formGroup]='BankForm' (ngSubmit)='Addbank()'>
                <!-- <label class="form-label">* Select Bank</label> -->
                <div class="form-group">
                    <select class="form-control form-control-sm"  formControlName='bankName'>
                        <option value="" disabled selected hidden class="placeholder">Select Bank</option>
                        <option value="{{bank.name}}" *ngFor="let bank of bankName">{{bank.name}}</option>
                    </select>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="IFSC" formControlName='ifsc'>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="tel" class="form-control" placeholder="Account No." formControlName='accnumber'>
                </div>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="Name" formControlName='name'>
                </div>

                <div class="submit-btn">
                    <button mat-raised-button color="primary" class="subbutton" [hidden]="otpButtonDisable">GetOtp
                    </button>
                </div>
                <div *ngIf="otpButtonDisable==true">
                    <div class="form-group" appearance="fill">
                        <input type="text" class="form-control" placeholder="OTP" formControlName='otp'>
                    </div>

                    <div class="submit-btn">
                        <button mat-raised-button color="primary" class="subbutton">Submit </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-12" *ngIf="banktype=='Google Pay'||banktype=='Phone Pay'||banktype=='Paytm'||banktype=='Pay Zapp'">
            <form [formGroup]='upiForm' (ngSubmit)='Addupi()'>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="UPI No." formControlName='upino'>
                </div>
                <div *ngIf="upiForm.get('upino').invalid && (upiForm.get('upino').dirty || upiForm.get('upino').touched)">
                    <div *ngIf="upiForm.get('upino').errors?.pattern" style="color:red"><small>Enter a valid 10-digit Upi number.</small></div>
                  </div>
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="Name" formControlName='name'>
                </div>
              
                <div class="submit-btn" [hidden]="otpButtonDisable">
                    <button mat-raised-button color="primary" class="subbutton">GetOtp </button>
                </div>

                <div *ngIf="otpButtonDisable==true">
                    <div class="form-group" appearance="fill">
                        <input type="text" class="form-control" placeholder="OTP" formControlName='otp'>
                    </div>

                    <div class="submit-btn">
                        <button mat-raised-button color="primary" class="subbutton">Submit </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12" *ngIf="banktype =='UPI'">
            <form [formGroup]='upiIdForm' (ngSubmit)='AddupiId()'>
                <div class="form-group" appearance="fill">
                    <input id="upiId" type="text" class="form-control" placeholder="UPI Id" formControlName='upiId'>
                </div>
                <div *ngIf="upiIdForm.get('upiId').invalid && (upiIdForm.get('upiId').dirty || upiIdForm.get('upiId').touched)">
                    <div *ngIf="upiIdForm.get('upiId').errors?.pattern" style="color:red"><small> Enter a valid UPI ID.</small></div>
                  </div> 
                <div class="form-group" appearance="fill">
                    <input type="text" class="form-control" placeholder="Name" formControlName='upiname'>
                </div>

                <div class="submit-btn" [hidden]="otpButtonDisable">
                    <button mat-raised-button color="primary" class="subbutton">GetOtp </button>
                </div>

                <div *ngIf="otpButtonDisable==true">
                    <div class="form-group" appearance="fill">
                        <input type="text" class="form-control" placeholder="OTP" formControlName='upiotp'>
                    </div>

                    <div class="submit-btn">
                        <button mat-raised-button color="primary" class="subbutton">Submit </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</ng-template>

<ng-template #success>
    <div class="modal-body">
        <mat-list class="setmat">
            <!-- <div class="text-right"><a type="button" class="closes" data-dismiss="modal">
                    <mat-icon (click)="modalRef.hide()">close</mat-icon>
                </a></div> -->

            <div class="text-center">

                <!-- <mat-icon class="setback1">cancel</mat-icon> -->
                <h4 class="trantion">Are You Sure</h4>

                <div class="submit-btn" [hidden]="otpButtonDisable">
                    <button mat-raised-button color="primary" class="subbutton" (click)="getotp()">GetOtp First</button>
                </div>

                <form [formGroup]='deletebankform' class="setdeletform" (ngSubmit)='deletebank(bankid)'
                    *ngIf="otpButtonDisable==true">

                    <div>
                        <div class="form-group" appearance="fill">
                            <input type="text" class="form-control" placeholder="OTP" formControlName='otp'>
                        </div>

                        <div class="submit-btn">
                            <button mat-raised-button color="primary" class="subbutton">delete </button>
                            <!-- <button class="backbutton" (click)="modalRef.hide()">Close</button> -->
                        </div>
                    </div>
                </form>
                <div class="submit-btn">
                    <button mat-raised-button color="primary" class="subbutton" (click)="modalRef.hide()">Close</button>
                </div>

            </div>

        </mat-list>

    </div>
</ng-template>