import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { Clipboard } from '@angular/cdk/clipboard';
import { UsersService } from '../services/users.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-wallet-deposit',
  templateUrl: './wallet-deposit.component.html',
  styleUrls: ['./wallet-deposit.component.scss']
})
export class WalletDepositComponent implements OnInit {
  depositeForm: FormGroup;
  depositeidForm: FormGroup;
  depositeAmountForm: FormGroup;
  minAmount: any = 500;
  loginButtonDisable = false;
  file: File = null;
  banktype: any;
  depositmethod: any;
  token: any;
  walletuser: any;
  cardImageBase64: any;
  fileimage:any;
  webimage: any;
  setamount: any;
  amountdata: any;
  createid: any;
  type: any;
  depositdata: any;
  iddeposite: any;
  modalRef: BsModalRef;
  offers: any;
  offerid: any;
  bonus: any;
  maxamount: any;
  maxamounts: any;
  userdata: any;
  urid:any;
  constructor(
    private _location: Location,
    private clipboard: Clipboard,
    private usersService: UsersService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
  ) {
    this.route.paramMap.subscribe(param => {
      this.type = param.get('type') // (+)Converts string 'id' to number
    });
  }
  async ngOnInit(): Promise<void> {
    this.createFrom();
    this.createamount();
    this.depositeAForm();
    this.getoffers();
    this.token = sessionStorage.getItem("token");
    this.getuser();
    var getamount = sessionStorage.getItem('depositamount');
    this.amountdata = JSON.parse(getamount);
    // console.log(this.amountdata);
    if (this.amountdata) {
      this.setamount = this.amountdata.coins;
    }
    var data = sessionStorage.getItem('details');
    this.iddeposite = JSON.parse(data);
    var data = sessionStorage.getItem('createid');
    this.createid = JSON.parse(data);


    // console.log(this.createid);

    this.userdata = await JSON.parse(sessionStorage.getItem('userDetails'));
    // console.log(userdata)
    if (this.userdata.details.manager == "PRTCLUB") {
      this.minAmount = 100;
    }
  }
  getamount(event) {
    var amount = event.target.value;
    if (this.userdata.details.manager == "PRTCLUB") {
      if (amount > 99) {
        this.maxamount = amount;
      }
    } else {
      if (amount > 499) {
        this.maxamount = amount;
      }
    }


    // alert(this.maxamount)
    //this.getpamentmethod(this.walletuser);
  }

  async getoffers() {
    const user = await JSON.parse(sessionStorage.getItem('userDetails'));
    // console.log(user)
    var data = { filter: { manager: user?.details?.manager } }
    this.usersService.post_api("get-offer/", data).subscribe((response: any) => {
      // console.log(response);
      let arr = [];
      if (response?.data) {
        for (var i = 0; i < response?.data?.length; i++) {
          let arr_ount = response?.data[i]?.apply_user?.filter((data) => [user?.details?.username].includes(data));
          if (arr_ount?.length < response?.data[i].max_apply || response?.data[i].max_apply == -1) {
            arr.push(response?.data[i])
          }
        }
      }
      // console.log(arr)
      this.offers = arr;
    })
  }

  backClicked() {
    // console.log('test')
    this._location.back();
    this.createamount();
    this.createFrom();
  }
  createamount() {
    this.depositeForm = this.fb.group({
      // amount: ['', [Validators.required, Validators.min(this.minAmount)]],
      image: ['', [Validators.required]],
    })
  }
  depositeAForm() {
    this.depositeAmountForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(this.minAmount)]],
      // image: ['', [Validators.required]],
    })
  }
  createFrom() {
    this.depositeidForm = this.fb.group({
      image: ['', [Validators.required]],
    })
  }

  async getuser() {
    const user = await JSON.parse(sessionStorage.getItem('userDetails'));
    this.urid= user.details._id;
      if (this.setamount) {
        this.maxamounts = this.setamount;
        this.getpamentmethod(this.urid);
      }
  
  }
async enteramount() {
    if (!this.depositeAmountForm.value.amount) {
      this.toastr.error("Please Enter Amount");
      return;
    } else if (this.depositeAmountForm.value.amount < this.minAmount) {
      this.toastr.error("amount can't be less than " + this.minAmount);
      return;

    } else{

      // console.log(this.depositeAmountForm);
      this.depositeAmountForm.value.amount = this.maxamounts;
      this.maxamounts = this.maxamount;
      // console.log(this.maxamounts);
      const user = await JSON.parse(sessionStorage.getItem('userDetails'));
      this.urid= user.details._id;
      this.getpamentmethod(this.urid);
    }
  }

  getpamentmethod(id) {
     //console.log(id);
    if (id) {
      // console.log(this.maxamounts)
      var data = { user_id: id }
      this.usersService.walletPost("user-deposit-method", data).subscribe((response: any) => {
      // this.usersService.postdatastaging("getPaymentMethod/" + this.token, method).subscribe((response: any) => {
         console.log(response);
        let bankarry = [];
        let googlepay = [];
        let paytmarry = [];
        let phonpayarry = [];
        let upiarry = [];
        let barcodearry = [];
        let payzapp = [];
        response.data.forEach(value => {
          if (value.paymenttype == 'Bank') {

            let total_bank_pay = response.data.filter((val) => { return val.paymenttype == 'Bank' && val.any == true });
            if (total_bank_pay.length == 0) {
              let total_bank_pay_not_any = response.data.filter((val) => { return val.paymenttype == 'Bank' && val.any == false });
              let arr = total_bank_pay_not_any.filter((val) => { return val.minLimit <= this.maxamounts && this.maxamounts <= val.maxLimit });
              bankarry = [...arr];
              return;
            }
            else {
              bankarry = [...total_bank_pay];
            }

          } else if (value.paymenttype == 'Google Pay') {

            let total_bank_pay = response.data.filter((val) => { return val.paymenttype == 'Google Pay' && val.any == true });
            if (total_bank_pay.length == 0) {
              let total_bank_pay_not_any = response.data.filter((val) => { return val.paymenttype == 'Google Pay' && val.any == false });
              let arr = total_bank_pay_not_any.filter((val) => { return val.minLimit <= this.maxamounts && this.maxamounts <= val.maxLimit });
              googlepay = [...arr];
            }
            else {
              googlepay = [...total_bank_pay];

            }
          } else if (value.paymenttype == 'Paytm') {

            let total_bank_pay = response.data.filter((val) => { return val.paymenttype == 'Paytm' && val.any == true });
            if (total_bank_pay.length == 0) {
              let total_bank_pay_not_any = response.data.filter((val) => { return val.paymenttype == 'Paytm' && val.any == false });
              let arr = total_bank_pay_not_any.filter((val) => { return val.minLimit <= this.maxamounts && this.maxamounts <= val.maxLimit });
              paytmarry = [...arr];
            }
            else {
              paytmarry = [...total_bank_pay];

            }
          } else if (value.paymenttype == 'Phone Pay') {

            let total_bank_pay = response.data.filter((val) => { return val.paymenttype == 'Phone Pay' && val.any == true });
            if (total_bank_pay.length == 0) {
              let total_bank_pay_not_any = response.data.filter((val) => { return val.paymenttype == 'Phone Pay' && val.any == false });
              // no 
              let arr = total_bank_pay_not_any.filter((val) => { return val.minLimit <= this.maxamounts && this.maxamounts <= val.maxLimit });
              phonpayarry = [...arr];
            }
            else {
              phonpayarry = [...total_bank_pay];

            }
          }else if (value.paymenttype == 'Pay Zapp') {

            let total_bank_pay = response.data.filter((val) => { return val.paymenttype == 'Pay Zapp' && val.any == true });
            if (total_bank_pay.length == 0) {
              let total_bank_pay_not_any = response.data.filter((val) => { return val.paymenttype == 'Pay Zapp' && val.any == false });
              // no 
              let arr = total_bank_pay_not_any.filter((val) => { return val.minLimit <= this.maxamounts && this.maxamounts <= val.maxLimit });
              payzapp = [...arr];
            }
            else {
              payzapp = [...total_bank_pay];

            }
          }  else if (value.paymenttype == 'UPI') {

            let total_bank_pay = response.data.filter((val) => { return val.paymenttype == 'UPI' && val.any == true });
            if (total_bank_pay.length == 0) {
              let total_bank_pay_not_any = response.data.filter((val) => { return val.paymenttype == 'UPI' && val.any == false });
              let arr = total_bank_pay_not_any.filter((val) => { return val.minLimit <= this.maxamounts && this.maxamounts <= val.maxLimit });
              upiarry = [...arr];
            }
            else {
              upiarry = [...total_bank_pay];

            }
          } else {
            let total_bank_pay = response.data.filter((val) => { return val.paymenttype == 'Barcode' && val.any == true });
            if (total_bank_pay.length == 0) {
              let total_bank_pay_not_any = response.data.filter((val) => { return val.paymenttype == 'Barcode' && val.any == false });
              let arr = total_bank_pay_not_any.filter((val) => { return val.minLimit <= this.maxamounts && this.maxamounts <= val.maxLimit });
              barcodearry = [...arr];
            }
            else {

              barcodearry = [...total_bank_pay];

            }
          }
        });
        let statusany = [...bankarry, ...googlepay, ...paytmarry, ...phonpayarry, ...upiarry, ...barcodearry, ...payzapp]

        // this.
        this.depositmethod = response.data;

      });
    }

  }


  async copyToClip(value: string) {
    this.clipboard.copy(value);
    this.toastr.success("Copied !");

  };

  addbank(data) {
   
    this.banktype = data;
   // console.log(this.banktype);
  }

  getdays(date) {
    // Set the date we're counting down to
    var countDownDate = new Date(date).getTime();

    // Update the count down every 1 second
    // var x = setInterval(function () {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"


    if (distance < 0) {
      // clearInterval(x);
      return "EXPIRED";
    } else {
      return "Valid For " + days + " days " + hours + " hours "
        + minutes + " minutes ";
    }
    // return days + "d " + hours + "h "
    //   + minutes + "m " + seconds + "s ";
    // }, 1000);
  }

  setbonus(data) {
    // console.log(data);
    this.bonus = data
  }
  removeoffer() {
    this.offerid = '';
    this.bonus = '';
  }

  openmodel(success: TemplateRef<any>) {
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  onChange(event) {
    let me = this;
    const file = event.target.files[0];
     
this.fileimage= file;
//console.log(this.fileimage);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.cardImageBase64 = reader.result;
      // console.log(reader.result);
      let mimeType = me.cardImageBase64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      me.webimage = mimeType;
      // console.log(me.webimage);
    };
    reader.onerror = function (error) {
     // console.log('Error: ', error);
    };
  }

  async depositamount() {
    const user = await JSON.parse(sessionStorage.getItem('userDetails'));
    this.urid= user.details._id;
    if (this.maxamounts != '') {
    } else {
      this.loginButtonDisable = false;
    }
    if (!this.banktype) {
      this.toastr.error("Please Select Payment Method");
      return;
    } else if (!this.depositeForm.value.image) {
      this.toastr.error("Please Upload Payment screenshot");
      return;
    } else if (this.bonus) {
      this.loginButtonDisable = true;
     // console.log(this.maxamounts);
      var data = new FormData();
     // data.append("type", this.banktype.paymenttype);
      data.append("amount", this.maxamounts);
      data.append("user_id", this.urid);
      data.append("image", this.fileimage);
      data.append("offer_id", this.bonus._id);
      data.append("imagetype", this.webimage);
      //data.append("managertype", this.banktype.type);
      //data.append("managerId", '6298db693453531745fc9c8f');
      data.append("methedId", this.banktype._id);
      // console.log(data);
      // return;
      this.usersService.walletPost("create-deposit-request", data).subscribe((response: any) => {
        this.depositdata = response;
        if (response) {
          this.createFrom();
          this.createamount();
          this.depositeAForm();
          this.cardImageBase64='';
          document.getElementById("openModalButton").click();
          this.loginButtonDisable = false;
          this.depositeForm?.reset();
        }
      })
      return;
    } else if (!this.bonus) {
      this.loginButtonDisable = true;
      var data = new FormData();
      data.append("amount", this.maxamounts);
      data.append("user_id", this.urid);
      data.append("image", this.fileimage);
      //data.append("offer_id", this.bonus._id);
     // data.append("imagetype", this.webimage);
      //data.append("managertype", this.banktype.type);
      //data.append("managerId", '6298db693453531745fc9c8f');
      data.append("methedId", this.banktype._id);
      // console.log(data);
      this.usersService.walletPost("create-deposit-request" ,data).subscribe((response: any) => {
        this.depositdata = response;
        if (response) {
          this.createFrom();
          this.createamount();
          this.depositeAForm();
          this.cardImageBase64='';
          document.getElementById("openModalButton").click();
          this.loginButtonDisable = false;
          this.depositeForm?.reset();
        }
      })
    }

  }

  depositidamount() {
    if (this.amountdata.username) {
      // console.log('username');

      if (this.setamount != '') {
      } else {
        this.loginButtonDisable = false;
      }
      if (!this.setamount) {
        this.toastr.error("Please Enter Amount");
        return;
      } else if (!this.depositeidForm.value.image) {
        this.toastr.error("Please Upload Payment screenshot");
        return;
      } else if (!this.banktype) {
        this.toastr.error("Please select a Payment method");
        return;
      } else if (this.setamount < this.minAmount) {
        this.toastr.error("amount can't be less than " + this.minAmount);
        return;

      } else {
        this.loginButtonDisable = true;
        var data = new FormData();
       // alert('drggddf');
        data.append("type", this.createid.type);
        data.append("typeId", this.createid.typeId);
        data.append("amount", this.setamount);
        data.append("paymentType", this.banktype.paymenttype);
        data.append("image", this.fileimage);
        data.append("user_id", this.urid);
        data.append("imagetype", this.webimage);
        data.append("depositId", this.banktype._id);
        data.append('sites', this.createid._id);
        data.append('username', this.amountdata.username);
        //alert(this.createid._id)
        //return
        this.usersService.walletPost("createMysites", data).subscribe((response: any) => {
          this.depositdata = response;
          if (response) {
            this.createFrom();
            this.createamount();
            this.depositeAForm();
            this.cardImageBase64='';
            document.getElementById("openModalButton").click();
            this.loginButtonDisable = false;
            this.depositeidForm?.reset();
            // this.toastr.success(response.message);
          }
        })
      }
    } else {
      if (this.setamount != '') {
      } else {
        this.loginButtonDisable = false;
      }
      if (!this.setamount) {
        this.toastr.error("Please Enter Amount");
        return;
      } else if (!this.depositeidForm.value.image) {
        this.toastr.error("Please Upload Payment screenshot");
        return;
      } else if (!this.banktype) {
        this.toastr.error("Please select a Payment method");
        return;
      } else if (this.setamount < this.minAmount) {
        this.toastr.error("amount can't be less than " + this.minAmount);
        return;

      } else {
        this.loginButtonDisable = true;
        var data = new FormData();
       data.append("type", this.iddeposite.type);
        data.append("typeId", this.iddeposite.typeId);
        data.append("user_id", this.urid);
       data.append("mysiteId", this.iddeposite._id);
        data.append("amount", this.setamount);
        data.append("paymentType", this.banktype.paymenttype);
        data.append("image", this.fileimage);
        data.append("depositId", this.banktype._id);
        this.usersService.walletPost("depositInsite", data).subscribe((response: any) => {
          this.depositdata = response;
          if (response) {
            this.createFrom();
            this.createamount();
            this.depositeAForm();
            this.cardImageBase64='';
            document.getElementById("openModalButton").click();
            this.loginButtonDisable = false;
            this.depositeidForm?.reset();
            // this.toastr.success(response.message);
          }
        })
      }
    }

  }


  ngOnDestroy() {
    sessionStorage.removeItem('depositamount');
  }

}
